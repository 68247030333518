import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import type FunnelKey from "src/constants/funnelKey";
import Loader from "../../Loader/Loader";
import type { SignupCustomStep } from "../SignupCustom.types";
import { SignupCustomStepType } from "../SignupCustom.types";
import {
  GenericTemplateFunnelLocalStorageKeys,
  lsKey
} from "../campaigns/FactorySignupCustomCampaign";
import { StorageController } from "src/state/StorageBloc/StorageBloc";

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SignupCustomStepAction: FC<{
  step: SignupCustomStep;
  onStepCompleted: (step: SignupCustomStep) => Promise<boolean> | boolean;
  funnelKey?: string;
}> = ({ step, onStepCompleted, funnelKey }) => {
  const stepTypeMatch = step.type === SignupCustomStepType.Action;

  React.useEffect(() => {
    if (!stepTypeMatch) return;

    void step.action().finally(() => {
      const lsk = lsKey(
        (funnelKey ?? "") as FunnelKey,
        `${GenericTemplateFunnelLocalStorageKeys.CustomStepActionPrefix}evaluate-data`
      );
      StorageController.setItem(lsk, "true");
      void onStepCompleted(step);
    });
  }, [stepTypeMatch]);

  return stepTypeMatch ? (
    <Loader fixed active>
      <Centered>Preparing the next step..</Centered>
    </Loader>
  ) : (
    <></>
  );
};

export default SignupCustomStepAction;
