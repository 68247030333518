import type { FC } from "react";
import React, { useEffect } from "react";
import createTrackEvent from "src/lib/createTrackEvent";
import { tracker, useBloc, userPreferences } from "src/state/state";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import RenderSignupCustomStep from "src/ui/components/SignupCustomContent/RenderSignupCustomStep";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";

const useCustomCampaignTracking = () => {
  const [
    { campaign },
    { currentStep, campaignPrettyName, campaignFunnelKey, getActiveCampaign }
  ] = useBloc(SignupCustomBloc);

  useEffect(() => {
    if (!currentStep || !campaign) return;

    // define user attribute values to be set at at the end of this function
    const userAttributeData = {
      status: "started",
      campaignPrettyName,
      campaignFunnelKey
    };

    const isSuccessStep = currentStep.type === SignupCustomStepType.SuccessPage;
    const campaignSteps = getActiveCampaign().steps();
    const lastSuccessStep = campaignSteps.findLast(
      (step) => step.type === SignupCustomStepType.SuccessPage
    );
    const isLastSuccessStep =
      isSuccessStep && currentStep.path === lastSuccessStep?.path;

    const trackDataQuestionnaire =
      currentStep.type === SignupCustomStepType.Questionnaire
        ? {
            "Questionnaire Name": currentStep.questionnaireName,
            "Questionnaire ID": currentStep.questionnaireId
          }
        : {};

    // add info page specific data
    const trackDataInfoPage =
      currentStep.type === SignupCustomStepType.InfoPage
        ? {
            "Is Success Step": isSuccessStep
          }
        : {};

    // collect general tracking data
    const trackData = {
      "Campaign Name": campaignPrettyName,
      "Step Name": currentStep.name,
      "Step Type": currentStep.type,
      "Step Path": currentStep.path,
      "Campaign ID": campaign,
      "Funnel Key": campaignFunnelKey,
      ...trackDataQuestionnaire,
      ...trackDataInfoPage
    };

    if (campaign && userAttributeData.campaignPrettyName) {
      // track the current step on backend
      tracker.track(createTrackEvent("Signup Campaign Step Viewed"), {
        data: trackData
      });

      // track success event when the success step is reached
      if (isSuccessStep && isLastSuccessStep) {
        userAttributeData.status = "finished";
        tracker.track(createTrackEvent("Signup Campaign Completed"), {
          data: trackData
        });
      }

      // set user preferences, for the backend to then sync with other tools
      void userPreferences.updateUserPreferences({
        [UserPreferenceKeys.lastSignupFunnel]:
          userAttributeData.campaignFunnelKey,
        [`${UserPreferenceKeys.signupFunnelStatus}.${campaign}`]:
          userAttributeData.status
      });
    }

    // scroll to top of page
    window.scrollTo({
      top: 0,
      left: window.scrollX,
      behavior: "smooth"
    });
    window.nineConfig?.mainScrollTarget?.scrollTo({
      top: 0,
      left: window.scrollX,
      behavior: "smooth"
    });
    document.body.scrollTop = 0;
  }, [currentStep?.path]);
};

const SignupCustomCampaign: FC = () => {
  const [{ key, campaign }, { currentStep, nextStep }] =
    useBloc(SignupCustomBloc);
  useCustomCampaignTracking();

  const handleStepCompleted = async (): Promise<boolean> => {
    await nextStep();
    return false;
  };

  return (
    <RenderSignupCustomStep
      key={key}
      step={currentStep}
      onStepCompleted={handleStepCompleted}
      funnelKey={campaign}
    />
  );
};

export default SignupCustomCampaign;
