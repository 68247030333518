import type { FC } from "react";
import React from "react";
import CustomQuestionnaire from "src/ui/components/CustomQuestionnaire/CustomQuestionnaire";
import type {
  SignupCustomStep,
  SignupCustomStepQuestionnaire as SignupCustomStepQuestionnaireType
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";

const SignupCustomStepQuestionnaireContent: FC<{
  step: SignupCustomStepQuestionnaireType;
  onStepCompleted: (
    step: SignupCustomStepQuestionnaireType
  ) => Promise<boolean> | boolean;
}> = ({ step, onStepCompleted }) => {
  const [showQuestionnaire, setShowQuestionnaire] = React.useState(true);

  const handleSubmit = async (): Promise<boolean> => {
    setShowQuestionnaire(false);
    return onStepCompleted(step);
  };

  return (
    <>
      <nine-center
        style={{
          opacity: showQuestionnaire ? 1 : 0
        }}
      >
        <CustomQuestionnaire
          useRouting
          onSubmit={handleSubmit}
          id={step.questionnaireId}
          resetScrollOnEachStep
          answerOverrides={step.answerOverrides}
          filterAnswerOptions={step.filterAnswerOptions}
        />
      </nine-center>
    </>
  );
};

const SignupCustomStepQuestionnaire: FC<{
  step: SignupCustomStep;
  onStepCompleted: (step: SignupCustomStep) => Promise<boolean> | boolean;
}> = ({ step, onStepCompleted }) => {
  const isQuestionnaire = step.type === SignupCustomStepType.Questionnaire;
  return isQuestionnaire ? (
    <SignupCustomStepQuestionnaireContent
      step={step}
      onStepCompleted={onStepCompleted}
    />
  ) : (
    <></>
  );
};

export default SignupCustomStepQuestionnaire;
